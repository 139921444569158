<template>
  <div style="min-width: 1900px;">
    <div class="newsListDetail_bg scaleBanner">
      <div class="newsList_bg_name moveTopTitle">新闻中心</div>
    </div>
    <div class="center_title">
      <img
        src="../../static/newImg2/home.png"
        width="16px"
        @click="toPage('/')"
      />
      <span class="center_home"
        ><span style="color: #8c8f92" @click="toPage('/newsList')"
          >&nbsp;&nbsp; > &nbsp; &nbsp;{{
            type.indexOf("版块") > -1 ? type : "新闻资讯"
          }}&nbsp;&nbsp;
        </span>
        <span style="color: #8c8f92" @click="toPage('/newsList', { id: index })"
          >>&nbsp;&nbsp;</span
        >
        {{ info.newsTitle }}</span
      >
    </div>
    <!-- <div class="newsListDetail_head">
      <div class="newsListDetail_head_title" @click="toPath('/newsList')">新闻资讯 </div>
      <div class="newsListDetail_head_title">&nbsp;>&nbsp; <span class="active">{{ info.newsTitle }}</span></div>
    </div> -->
    <div class="newsListDetail_list">
      <div class="newsListDetail_list_title">
        {{ info.newsTitle }}
      </div>
      <div class="newsListDetail_list_time">
        发布时间：{{ info.year }}-{{ info.day }}
      </div>
      <div style="margin: 24px auto">
        <img :src="info.newsCover" style="width: 100%" />
      </div>
      <!-- 新闻详情视频模块 -->
      <div
        style="
          width: 100%;
          background-color: #000;
          margin-bottom: 20px;
          position: relative;
        "
        v-if="info.videoUrl"
      >
        <video
          class="video-js vjs-default-skin videoNode"
          :src="info.videoUrl"
          ref="videoNode"
          width="1200px"
          :poster="info.videoImage"
          data-setup="{}"
        ></video>
      </div>
      <div class="newsListDetail_list_value" v-html="info.newsContent"></div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import {
  getAccessToken,
  getSignature,
  getCompanyDetailById,
} from "@/api/index";
export default {
  name: "newsListDetail",
  data() {
    return {
      info: {},
      name: "",
      type: "",
      index: 0,
      companyItem: null,
    };
  },
  mounted() {
    let company;
    if (this.$route.query.companyId) {
      getCompanyDetailById(this.$route.query.companyId).then((res) => {
        company = res.data;
        this.$store.commit("setCompany", company);
        this.companyItem = company;
        const id = this.$route.query.id;
        this.name = this.$route.query.name;
        // this.type = this.$route.query.type;
        this.index = this.$route.query.index;
        this.getInfo(id);
      });
    } else {
      company = this.$store.getters.getCompany;
      this.companyItem = company;
      const id = this.$route.query.id;
      this.name = this.$route.query.name;
      // this.type = this.$route.query.type;
      this.index = this.$route.query.index;
      this.getInfo(id);
    }
    setTimeout(()=>{
      window.scroll(0, 0);
    }, 500)
  },
  methods: {
    getWXInfo() {
      const that = this;
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp;
        const nonceStr = res.data.noncestr;
        const signature = res.data.signature;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: "wxad8dd02ae97d307f", // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
        });
        wx.ready(function() {
          //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: that.info.newsTitle, // 分享标题
            desc: that.info.newsIntroduction
              ? that.info.newsIntroduction
              : that.companyItem.companyDesc, // 分享描述
            link: decodeURIComponent(window.location.href.split('?')[0] + '?id=' + that.$route.query.id + '&index=' + that.index +
              "&companyId=" +
              that.companyItem.companyId +
              "&companyArr=" +
              JSON.stringify(that.$store.getters.getCompanyTier)), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: that.info.newsCover, // 分享图标
          };
          wx.updateAppMessageShareData(obj);
          wx.updateTimelineShareData(obj);
        });
      });
    },
    getInfo(id) {
      //获取独立的数据
      axios.get(`https://admin.zoneyu.cn/cms/api/app/news/${id}`).then((res) => {
        this.info = res.data.data;
        this.getWXInfo();
        const updateTime = new Date(this.info.publishTime);
        this.info.day =
          updateTime.getDate() > 9
            ? updateTime.getDate()
            : "0" + updateTime.getDate();
        this.info.year =
          updateTime.getFullYear() +
          "." +
          (updateTime.getMonth() + 1 > 9
            ? updateTime.getMonth() + 1
            : "0" + (updateTime.getMonth() + 1));
        this.$nextTick(() => {
          if (this.$refs.videoNode) {
            this.player = videojs(this.$refs.videoNode, {
              preload: "auto",
              controls: true,
            });
            const name = document.getElementsByClassName("vjs-poster")[1]
              .childNodes[0];
            name.src = this.info.videoImage;
          }
        });
      });
    },
    toPage(path, query) {
      if (path == "/") {
        if (this.companyItem.companyLevel == 1) {
          this.$router.push({
            path: `/`,
          });
        } else if (this.companyItem.companyLevel == 2) {
          this.$router.push({
            path: `/ProvincialCompany`,
          });
        } else if (this.companyItem.companyLevel == 3) {
          this.$router.push({
            path: `/cityCompany`,
          });
        } else if (this.companyItem.companyLevel == 4) {
          this.$router.push({
            path: `/areaCompany`,
          });
        }
      } else {
        this.$router.push({ path, query });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.newsListDetail_bg {
  height: 560px;
  position: relative;
  overflow: hidden;
  width: 100%;
  background-image: url("../../static/image/newsList_bg.png");
  background-repeat: no-repeat;
  background-size: cover;

  .newsList_bg_name {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 56px;
    color: #ffffff;
    text-align: center;
    letter-spacing: 1px;
    font-family: 黑体;
  }
}

.newsListDetail_head {
  width: 1280px;
  margin: 20px auto;
  font-size: 16px;
  font-weight: 400;
  color: #858585;
  line-height: 20px;
  overflow: hidden;

  .newsListDetail_head_title {
    margin-top: 40px;
    display: inline-block;
    cursor: pointer;
  }

  .active {
    color: #000;
  }
}

.center_title {
  width: 1280px;
  margin: 0 auto;
  margin-top: 54px;
  z-index: 9;
  position: relative;

  & > img {
    cursor: pointer;
  }

  .center_home {
    font-weight: 400;
    font-size: 16px;
    color: #1d1b19;
    vertical-align: top;
    cursor: pointer;
  }
}

.newsListDetail_list {
  margin: 100px auto 40px auto;
  width: 1280px;
  text-align: center;

  .newsListDetail_list_title {
    color: #333;
    font-size: 26px;
    text-align: left;
  }

  .newsListDetail_list_time {
    font-size: 12px;
    font-weight: 400;
    color: #abaeb0;
    line-height: 17px;
    margin-top: 20px;
    text-align: left;
  }

  .newsListDetail_list_value {
    margin-top: 40px;
    text-align: left;
    margin-bottom: 40px;
    min-height: 1100px;
  }
}

::v-deep .video-js {
  width: 100% !important;
  max-height: 500px;
}
</style>
